import React, { Component } from "react";
import { Trans } from "react-i18next";

export default class NavBarLogout extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="navbar-menu-logout">
                <a href="/logout" >
                    <Trans i18nKey="logout">Se déconnecter</Trans>
                </a>
            </div>
        );
    }
}