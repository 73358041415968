import React, { Component } from "react";
import { Trans } from "react-i18next";


export default class NavBarServices extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <li>
                <a href="/404">
                    <Trans i18nKey="services">Services</Trans>
                </a>
            </li>
        );
    }
}