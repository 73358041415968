import React, { Component } from "react";
import NavBarSubMenu from "./SousMenu/NavBarSubMenu";
import { Trans } from "react-i18next";
import i18n from "../../../i18n";

import SearchLogo from "../../../styles/image/tarifs_et_nouveautes/imgs/loupe.png"
import CarresOrange from "../../../styles/image/UniverseOfGaea-orange.png";


export default class NavBarDesktop extends Component {
  constructor(props) {
    super(props);
    this.toggleClickState = this.toggleClickState.bind(this);
    this.changeLangue = this.changeLangue.bind(this);
    this.changeLangueToEn = this.changeLangueToEn.bind(this);
    this.changeLangueToFr = this.changeLangueToFr.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.empecheRedirection = this.empecheRedirection.bind(this);
    this.compareClicks = this.compareClicks.bind(this);
    this.determineElementsNavigationARendre =
      this.determineElementsNavigationARendre.bind(this);
    this.setState = this.setState.bind(this);
    this.actualitesClick = this.actualitesClick.bind(this);

    this.state = {
      clickedToDisplayBarreBlanche: false,
      lastClicked: "",
      lastClickedSameAsThisClick: true,
      numberOfSameClicksInARow: 1,
      langue: "fr",
      indiceLangue: 1,
      registeredToNewsletter: "",
    };
  }

  changeLangueToEn = () => {
    var xhr = new XMLHttpRequest();
    var langue_utilisateur;
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        langue_utilisateur = JSON.parse(this.response);
        if (langue_utilisateur["langue"] == "fr") {
          window.location.href = window.location.pathname.replace("/fr", "/en");
        }
      }
    };
    xhr.open("GET", "/recuperer_langue", true);
    xhr.send(null);
    this.setState({ indiceLangue: this.state.indiceLangue });
  };

  changeLangueToFr = () => {
    var xhr = new XMLHttpRequest();
    var langue_utilisateur;
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        langue_utilisateur = JSON.parse(this.response);
        if (langue_utilisateur["langue"] == "fr") {
          window.location.href = window.location.pathname.replace("/fr", "/en");
        } else {
          window.location.href = window.location.pathname.replace("/en", "/fr");
        }
      }
    };
    xhr.open("GET", "/recuperer_langue", true);
    xhr.send(null);
    this.setState({ indiceLangue: this.state.indiceLangue + 1 });
  };

  //Affichage coonditionnels des boutons home, qui sommes nous, communauté, shop, nouveauté et actualités
  determineElementsNavigationARendre(roleUtilisateur) {
    let dispositionDeLaNavigation = "accueil";
    //console.log("éléments de navigation à rendre:");
    //console.log(roleUtilisateur);

    if (
      (roleUtilisateur == "USER") |
      (roleUtilisateur == "COMPANY") |
      (roleUtilisateur == "CITY")
    ) {
      dispositionDeLaNavigation = "interface";
    } else {
      dispositionDeLaNavigation = "accueil";
    }
    return dispositionDeLaNavigation;
  }

  //Cette fonction est crée pour empêcher les redirections vers les uatres pages via les éléments a
  //Pour l'utiliser, ajouter comme par ex: <a onClick=this.empecheRedirection ></a>
  empecheRedirection(event) {
    //console.log("L'évènement récupéré est le suivant:");
    //console.log(event);
    event.preventDefault();
  };

  //Récupération de l'évènement de changement
  //détermination de l'action à suivre à l'aide de toggleClickState; c'est le thisClick, transformé en lastClick, qui détermine le style de NavBarreBlanche (display = none ou display), à condition que clicked soit true.
  //Pour les éléments qui ne doivent pas afficher la barre pour l'instant, on appelle la fonction mais passe clicked à false.
  //Cela étant, pour par la suite changer le comportement, il suffit de passer clicked à true, puis à se rendre dans la fonction rendContenu du composant BarreBlanche.jsx
  handleClick(actionDuBouton, nomDuBouton) {
    //console.log("On pass par handle click et on veut: ");
    //console.log(actionDuBouton);
    if (actionDuBouton == "afficherBarreBlanche") {
      //console.log("nom du bouton:")
      //console.log(nomDuBouton);
      this.toggleClickState({
        clickedToDisplayBarreBlanche: true,
        thisClick: nomDuBouton,
      });
    } else if (actionDuBouton == "redirigerVersAutrePage") {
      this.toggleClickState({
        clickedToDisplayBarreBlanche: false,
        thisClick: nomDuBouton,
      });
    }
  }

  //A chaque nouveau clique, on compare avec le précédent. Si il est différent, on enregistre sa valeur sous lastClicked, qui détermine par la suite le contenu de la barre blanche, et on remet le nombre de clicks similaires d'affilé à 1. Si le nombre est paire, l'utilisateur a cliqué deux fois (ou 4/6/8...) et on considère qu'il veut fermer la fenêtre. Cf ligne
  compareClicks(ancientClick, newClick) {
    let similarClicks = false;

    if (ancientClick != newClick) {
      this.setState({ lastClicked: newClick });
      this.setState({ numberOfSameClicksInARow: 1 });
      similarClicks = false;
    } else {
      similarClicks = true;
      this.setState({
        numberOfSameClicksInARow: this.state.numberOfSameClicksInARow + 1,
      });
    }
    //console.log("nombre de clicks similaires:");
    //console.log(similarClicks);
    return similarClicks;
  }

  toggleClickState = (data) => {
    this.setState({
      clickedToDisplayBarreBlanche: data.clickedToDisplayBarreBlanche,
      lastClickedSameAsThisClick: this.compareClicks(
        this.state.lastClicked,
        data.thisClick
      ),
      lastClicked: data.thisClick,
    });
  };

  // Affiche la barre blanche seulement si le user connecté n'est pas encore inscrit à la newsletter
  actualitesClick(e) {
    if (this.state.registeredToNewsletter) {
      this.handleClick("redirigerVersAutrePage", "Actualité");
    } else {
      this.handleClick("afficherBarreBlanche", "Actualité");
      this.empecheRedirection(e);
    }
  }

  closeMobileMenu = () => {
    this.setState({ clicked: false });
  };

  render() {
    let roleUtilisateur = this.props.userRole;
    console.log("roleUtilisateur", roleUtilisateur);
    return (
      <nav>
        <div className="div-navbar">
          <div className="div-logo-navbar">
            <a className="logo-repertoirevert" href="/" >
            </a>
          </div>

          <div className="div-navbar-menus">
            <div className="container-login-language-menu"
              style={
                this.determineElementsNavigationARendre(roleUtilisateur) ==
                  "interface"
                  ? {}
                  : {
                    display: "none",
                  }
              }
            >

              <div
                onClick={() => this.handleClick("afficherBarreBlanche", "Home")}
                className="div-menu "
              >
                <a
                  className="navbarHomeButton"
                  href=""
                  onClick={this.empecheRedirection}
                >
                  <Trans i18nKey="accueil">Accueil</Trans>
                </a>
              </div>

              <div
                onClick={() => {
                  this.handleClick("afficherBarreBlanche", "QuiSommesNous");
                }}
                className="div-menu "
                id="btn-qui-sommes-nous"
              >
                <a
                  href=""
                  onClick={this.empecheRedirection}
                  className="navBarQuiSommesNousButton"
                >
                  <Trans i18nKey="quisommes">Qui sommes-nous</Trans>
                </a>
              </div>

              {/* Redirection impossible pour faire apparaitre la barre blanche, de plus, la souris change d'icone en mouse over, des sections ont été modifiée et rajouter (log out / login) et déplacer, étant devenus des boutons unique. */}

              <div
                className="div-menu"
                onClick={() =>
                  this.handleClick("redirigerVersAutrePage", "Services")
                }
              >
                <a href="/404" className="navBarServicesButton">
                  <Trans i18nKey="services">Services</Trans>
                </a>
              </div>
              {/* crré un nouvelle fonction qui remlacera celle si afin d'afficher ou non le formulaire  */}
              <div
                className="div-menu"
                onClick={() =>
                  this.handleClick("afficherBarreBlanche", "Actualites")
                }
              >
                <a
                  href="/actualites"
                  className="navbarActualitesButton"
                  onClick={this.empecheRedirection}
                >
                  <Trans i18nKey="actualites">Actualités</Trans>
                </a>
              </div>

              <div
                className="div-menu"
                onClick={() =>
                  this.handleClick("redirigerVersAutrePage", "Nouveautés")
                }
              >
                <a href="/tarifs/COMPANY" className="navbarNouveautesButton">
                  <Trans i18nKey="nouveautes">Nouveautés</Trans>
                </a>
              </div>

              <div
                className="div-menu"
                onClick={() =>
                  this.handleClick("redirigerVersAutrePage", "Communauté")
                }
              >
                <a href="/communaute" className="navbarCommunauteButton">
                  <Trans i18nKey="communaute">Communauté</Trans>
                </a>
              </div>

              <div
                className="div-menu"
                onClick={() =>
                  this.handleClick("redirigerVersAutrePage", "Shop")
                }
              >
                <a href="/404" className="navbarShopButton">
                  <Trans i18nKey="shop">Shop</Trans>
                </a>
              </div>

              <div
                className="div-menu"
                onClick={() =>
                  this.handleClick("redirigerVersAutrePage", "Search")
                }
              >
                <a href="/404" className="navbarSearchButton">
                  <img src={SearchLogo} alt="" />
                </a>
              </div>

              <div
                className="div-menu"
                onClick={() =>
                  this.handleClick("redirigerVersAutrePage", "MonCompte")
                }
              >
                <a href="/profile/entreprise" className="navbarAccountButton">
                  <Trans i18nKey="compte">Mon Compte</Trans>
                </a>
              </div>
              <div className="div-menu">
                <div className="separatorLogout">|</div>
              </div>
              <div className="div-menu">
                <a className="navbarLogoutButton" href="/logout">
                  <Trans i18nKey="logout">Se déconnecter</Trans>
                </a>
              </div>
              <div className="navbarLangueButton">
                <div className="dropdown">
                  <button className="dropbtn">
                    <Trans i18nKey="langue">Language v</Trans>
                  </button>
                  <div className="dropdown-content">
                    <div className="fr" onClick={() => this.changeLangueToFr()}>
                      <a href="#">Français</a>
                    </div>
                    <div className="en" onClick={() => this.changeLangueToEn()}>
                      <a href="#">English</a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="div-menu" onClick={() => this.changeLangue()}>
                <span className="navbarLangueButton">FR|EN</span>
              </div> */}
              <div className="carresOrange">
                <a href="https://www.gaea21.org/" target="_blank">
                  <img src={CarresOrange} alt="" />
                </a>
              </div>
            </div>


            <div className="container-login-language-menu"
              style={
                this.determineElementsNavigationARendre(roleUtilisateur) ==
                  "accueil"
                  ? {}
                  : {
                    display: "none",
                  }
              }
            >
              <div
                onClick={() => this.handleClick("afficherBarreBlanche", "Home")}
                className="div-menu "
              >
                <a
                  className="navbarHomeButton"
                  href=""
                  onClick={this.empecheRedirection}
                >
                  <Trans i18nKey="accueil">Accueil</Trans>
                </a>
              </div>

              <div
                onClick={() => {
                  this.handleClick("afficherBarreBlanche", "QuiSommesNous");
                }}
                className="div-menu "
                id="btn-qui-sommes-nous"
              >
                <a
                  href=""
                  onClick={this.empecheRedirection}
                  className="navBarQuiSommesNousButton"
                >
                  <Trans i18nKey="quisommes">Qui sommes-nous</Trans>
                </a>
              </div>

              <div
                className="div-menu"
                onClick={() =>
                  this.handleClick("redirigerVersAutrePage", "Services")
                }
              >
                <a href="/404" className="navBarServicesButton">
                  <Trans i18nKey="services">Services</Trans>
                </a>
              </div>

              <div
                className="div-menu"
                onClick={() =>
                  this.handleClick("afficherBarreBlanche", "Actualites")
                }
              >
                <a
                  href="/actualites"
                  className="navbarActualitesButton"
                  onClick={this.empecheRedirection}
                >
                  <Trans i18nKey="actualites">Actualités</Trans>
                </a>
              </div>

              <div
                className="div-menu"
                onClick={() =>
                  this.handleClick("redirigerVersAutrePage", "Nouveautés")
                }
              >
                <a href="/tarifs/COMPANY" className="navbarNouveautesButton">
                  <Trans i18nKey="nouveautes">Nouveautés</Trans>
                </a>
              </div>
              <div
                className="div-menu"
                onClick={() =>
                  this.handleClick("redirigerVersAutrePage", "Communauté")
                }
              >
                <a href="/communaute" className="navbarCommunauteButton">
                  <Trans i18nKey="communaute">Communauté</Trans>
                </a>
              </div>

              <div
                className="div-menu"
                onClick={() =>
                  this.handleClick("redirigerVersAutrePage", "Shop")
                }
              >
                <a href="/404" className="navbarShopButton">
                  <Trans i18nKey="shop">Shop</Trans>
                </a>
              </div>

              <div
                className="div-menu"
                onClick={() =>
                  this.handleClick("redirigerVersAutrePage", "Search")
                }
              >
                <a href="/404" className="navbarSearchButton">
                  <img src={SearchLogo} alt="" />
                </a>
              </div>

              {/*<div onClick={() => this.handleClick("redirigerVersAutrePage", "S'inscrire")} className="div-menu ">
                                <a className="navbarInscriptionButton" href="/inscription-entreprise/">S'inscrire</a>
                            </div>*/}
              <div
                onClick={() => {
                  this.handleClick("afficherBarreBlanche", "Sinscrire");
                }}
                className="div-menu"
                id="btn-qui-sommes-nous"
              >
                <a
                  href=""
                  onClick={this.empecheRedirection}
                  className="navBarSinscrireButton"
                >
                  <Trans i18nKey="sinscrire">S'inscrire</Trans>
                </a>
              </div>
              <div className="div-menu">
                <div className="separator">|</div>
              </div>
              <div
                className="div-menu"
                onClick={() =>
                  this.toggleClickState({
                    clickedToDisplayBarreBlanche: true,
                    thisClick: "Login",
                  })
                }
              >
                <a className="navbarLoginButton">
                  <Trans i18nKey="login">Se connecter</Trans>
                </a>
              </div>
              <div className="navbarLangueButton">
                <div className="dropdown">
                  <button className="dropbtn">
                    <Trans i18nKey="langue">Language v</Trans>
                  </button>
                  <div class="dropdown-content">
                    <div class="fr" onClick={() => this.changeLangueToFr()}>
                      <a href="#">Français</a>
                    </div>
                    <div class="en" onClick={() => this.changeLangueToEn()}>
                      <a href="#">English</a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="div-menu" onClick={() => this.changeLangue()}>
                <span className="navbarLangueButton">FR|EN</span>
              </div> */}
              <div className="carresOrange">
                <a href="https://www.gaea21.org/" target="_blank">
                  <img src={CarresOrange} alt="" />
                </a>
              </div>
            </div>
          </div>

          {/*  Barre de navigation qui n'apparaît qu'au clic d'un élément du menu supérieur  */}
          <div className="navBarBlanche">
            {/* Cette opération fait en sorte que deux clics de suite sur le même bouton, la barre disparit, avec un de plus elle réaparait un autre de plus disparait ect */}
            {this.state.clickedToDisplayBarreBlanche && this.state.numberOfSameClicksInARow % 2 != 0 ?
              <NavBarSubMenu contenuARendre={this.state.lastClicked} langue={this.state.langue} />
              :
              ""
            }
          </div>
        </div>
      </nav>
    );
  }
}

