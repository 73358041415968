import React, { Component } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Redirect } from "react-router-dom";
import login from "../navBar/login";
import EvaluationSystem from "../homeCompany/associatedPrograms/AssociatedPrograms";
import md5 from "md5";

class BoxLogin extends React.Component {
    constructor(props) {
        super(props);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);       
        this.handleClickSubmit = this.handleClickSubmit.bind(this);             

        if (this.props.langue === "fr") {
            this.state = {
                texteMotDePasse: "Mot de Passe",
                texteValider: "Valider",
                erreur: "veuillez ressaisir votre identifiant "
            };
        } else {
            this.state = {
                texteMotDePasse: "Password",
                texteValider: "Confirm",
                erreur: "Please re-enter your information"
            };
        }
        this.state = {
            ...this.state,
            email: '',
            password:'',
            error: null,
        }
    }
    // this.state = {
    //   ...this.state,
    //   email: "",
    //   password: "",
    // };
//   }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }


//
    // Test Davy ajout requete axions vers security controller
    // handleClickSubmit () {
    //     let email = this.state.email;
    //     let password= md5(this.state.password);
    //     const objectLogin = {email : email, password: password};

  // Test Davy ajout requete axions vers security controller
  // handleClickSubmit () {
  //     let email = this.state.email;
  //     let password= md5(this.state.password);
  //     const objectLogin = {email : email, password: password};

  //     axios.post(
  //         `https://gaea21userdev.sustlivprogram.org/apictrl/login`,
  //         JSON.stringify(objectLogin))
  //         .then(response => {
  //         const {id, email, username}  = response.data;
  //         console.log(response.data);

  //             axios.post(
  //                 `/app/login`,
  //                 //{id: id})
  //                 //JSON.stringify({id:id, email:email, username:id, password:password}),
  //                 JSON.stringify(objectLogin),
  //                 {headers:{'Content-Type': 'application/json'}})
  //                 .then(response => {
  //                 const {user} = response.data;
  //                 console.log(user);

  //                 axios.post(
  //                     `/company/active`,
  //                     //{id: id})
  //                     JSON.stringify({id:id, email:email, username:username}))
  //                     .then(response => {
  //                     console.log(response.data);
  //                     if (response.data.activated) {
  //                         window.location.href = '/fr/profile/entreprise'
  //                     }
  //                 })
  //             })
  //     })
  // }

  //Code original qui fonctionne
  handleClickSubmit() {
    let email = this.state.email;
    let password = md5(this.state.password);
    const objectLogin = { email: email, password: password };

        axios.post(
            `https://gaea21user.sustlivprogram.org/apictrl/login`,
            JSON.stringify(objectLogin),
            {headers:{'Content-Type': 'application/json'}}) 
            .then(response => {
            const {id, email, username}  = response.data; 
            // console.log(response.data);
            // console.log(id);
            // console.log(email);
            // console.log(username);            
            
            // console.log(response.data);  

            if (response.data.error) {
                this.setState({ error: this.state.erreur });
              } else {
                this.setState({ error: null });
                axios.post(   
                    `/company/active`,
                    JSON.stringify({gaeaUserId:id, email:email, username:username}),
                    {headers:{'Content-Type': 'application/json'}})  
                    .then(response => {
                    // console.log(response.data);
                    if (response.data.activated) {                    
                        window.location.href = '/fr/profile/entreprise'
                    }            
                }) 
                .catch(error => {
                    console.log(error);
                    this.setState({ error: this.state.erreur });
                  });   
              }    
        })
    }

    render() {
        return (
            <>
                <img
                    className="smallImageLoginForm"
                    src="/images/clock-login.jpg"
                ></img>
                <span className="titreQuiSommesNous">Se connecter</span>

                <label className="labelEmailBoxLogin" htmlFor="Email">
                    Email
                </label>
                <input 
                    className="inputEmailBoxLogin"
                    type="text"
                    name="Email"
                    id="email"
                    value={this.state.email}
                    onChange={this.handleChangeEmail}
                ></input>
                <label className="labelPasswordBoxLogin" htmlFor="MotDePasse">
                    {this.state.texteMotDePasse}
                </label>
                <input
                    className="inputPasswordBoxLogin"
                    type="password"
                    name="MotDePasse"
                    id="password"
                    value={this.state.password}
                    onChange={this.handleChangePassword}
                ></input>
                <label className="labelErrorLocation" id="errorlocation">{this.state.error}</label>
                <button
                    className="inputSubmitBoxLogin"
                    style={{ cursor: "pointer" }}
                    id="btnn"
                    onClick={this.handleClickSubmit}
                >
                    {this.state.texteValider}
                </button>
                <a
                    className="redirectionInscriptionBoxLogin"
                    href="/inscription-entreprise"
                >
                    Pas encore inscrit ?
                </a>
                <a className="mdp" href="/recuperation">
    Mot de passe oublié ?
</a>

                <img
                    className="imageLoginForm"
                    src="/images/image-login.44b00b54.png"
                ></img>
            </>
        );
    }
}
export default BoxLogin;
