import $ from "jquery";

export default function login() {
  console.log("login appelé");
  var password = $("#password").val();
  //var email = $("#email").val();

  //$("#btnn").on("click",function (e) {
  $("#btnn").attr("disabled", true);

  console.log("Log in en cours");
  //console.log($("#password").val(),$("#email").val() );
  if ($("#password").val() && $("#email").val()) {
    password = md5($("#password").val());
    // $("#load").addClass('spinner-border');
    // $("#load").css({
    //     'role':'status',
    //     'aria-hidden':'true'
    // });
    $("#btnn").attr("disabled", true);
    console.log("ok");

    $.ajax({
      method: "POST",
      url: "https://gaea21user.sustlivprogram.org/apictrl/login",
      // url: "https://127.0.0.1:8001/apictrl/login",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        email: $("#email").val(),
        password: password,
      }),
      error: function () {
        //$("#load").removeClass('spinner-border');
        //$("#btnn").attr('disabled',false);
        document.getElementById("errorlocation").innerHTML =
          '<span style="color:red;">Une erreur est survenue. Veillez à saisir des informations conformes à votre compte ou essayez ultérieurement.</span>';
      },
    }).done(function (response) {
      console.log(response);
      let { id, username, email, message } = response;
      console.log(id);
      if (id) {
        $.ajax({
          method: "POST",
          url: "/company/active",
          contentType: "application/json",
          data: JSON.stringify({ gaeaUserId : id, email : email, username : username }),
        }).done((response) => {
          //Si le log est Ok
          console.log(response.activated);
          if (response.activated) {
            console.log("TEST!!!!" + response.message);

            // $.redirect("/profile/entreprise", {
            //   id: id,
            //   email: email,
            //   username: username,
            // });
            window.location.href = '/fr/profile/entreprise';

            //$.redirect('https://127.0.0.1:8000/fr/profile/entreprise', { 'id': id, 'email': email, 'username':  username });

            //location.href = '/profile/entreprise'
            //location.href = 'profile/entreprise'
          } 
        }).fail (function (xhr,status, error) {            
            document.getElementById("errorlocation").innerHTML = "<p>Veuillez ressaisir votre identifiant</p>";
            $("#load").removeClass("spinner-border");
            $("#btnn").attr("disabled", false);
          });
      } else {
        document.getElementById("errorlocation").innerHTML =
          "<p>" + message + "</p>";
        $("#load").removeClass("spinner-border");
        $("#btnn").attr("disabled", false);
      }
    });
  } else {
    document.getElementById("error").innerHTML =
      '<span style="color:red;">Veuillez remplir les champs</span>';
    console.log("ok");
  }
}