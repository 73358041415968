import React from "react";
import ReactDOM from "react-dom";

//Import des composants
import NavBarDesktop from "./NavBarDesktop/NavBarDesktop";
import NavBarReact from "./NavBarDesktop/NavBarReact";
import NavBarMobile from "./NavBarMobile/NavBarMobile";

//Style de la navBar
import './NavBarDesktop/NavBarDesktop.scss';
import './NavBarMobile/NavBarMobile.scss';
import './NavBarDesktop/NavBarReact.scss';
import './NavBarDesktop/SousMenu/NavBarSubMenu.scss';
import axios from "axios";

//Le Composant attent en Props que l'appel avec le UserRole
export default class NavBarController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole : "NOROLE"
    }
  }

  componentDidMount() {
    this.getRole();
  }

  getRole(){
    axios.get("/api/users/userRole").then(res => {
      const userRole = res.data;
      console.log("--------------------------------------------------------------");
      console.log(userRole);
      this.setState({ userRole });
    })
  }

  render() {
    return (
      <div className="container-navbar-react">
        {/* <NavBarDesktop userRole={userRole} /> */}
        <NavBarReact userRole={this.state.userRole} />
        <NavBarMobile userRole={this.state.userRole} />
      </div>
    );
  }
}

