import React, { Component } from "react";
import { Trans } from "react-i18next";

export default class NavBarLogon extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(e) {
        e.preventDefault();
        this.props.handleOnClick("Login");
    }

    render() {

        return (
            <div className="navbar-menu-logon">
                <a onClick={this.handleClick} href="" >
                    <Trans i18nKey="login">Se connecter</Trans>
                </a>
            </div>
        );
    }
}