import React, { Component } from "react";

export default class NavBarLanguage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayLanguageMenu: false,
        };

    }

    render() {
        const currentLanguage = this.props.currentLanguage
        return (
            <div className="container-navbar-language">
                <div className="dropdown-center">
                    <button type="button" className="navbar-changelanguage-button" control-id="ControlID-1" data-bs-toggle="dropdown" aria-expanded="false">
                        {currentLanguage == "fr" ? "Français" : ""}
                        {currentLanguage == "en" ? "English" : ""}
                    </button>
                    <ul className="dropdown-menu navbar-changelanguage-list">
                        <li>
                            <a className="dropdown-item" href="" onClick={this.props.onChangeToFr}>
                                Français
                            </a>
                        </li>
                        <li>
                            <a className="dropdown-item" href="" onClick={this.props.onChangeToEn}>
                                English
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );

    }
}
                 {/* <div className="header-changelanguage-button" >
                //     {currentLanguage == "fr" ? <p>Français</p> : ""}
                //     {currentLanguage == "en" ? <p>English</p> : ""}
                // </div>
                // {this.state.displayLanguageMenu ?
                //     <div className="header-changelanguage-list">
                //         <ul>
                //             <li>
                //                 <div onClick={this.props.onChangeToFr}>
                //                     <p>Français</p>
                //                 </div>
                //             </li>
                //             <li>
                //                 <div onClick={this.props.onChangeToEn}>
                //                     <p>English</p>
                //                 </div>
                //             </li>
                //         </ul>
                //     </div> : ""
                // } */}