import React, { Component } from "react";
import NavBarLogo from "./Components/NavBarLogo"
import NavBarHome from "./Components/NavBarHome"
import NavBarQuiSommeNous from "./Components/NavBarQuiSommeNous"
import NavBarServices from "./Components/NavBarServices"
import NavBarActualités from "./Components/NavBarActualités"
import NavBarNouveautés from "./Components/NavBarNouveautés"
import NavBarCommunauté from "./Components/NavBarCommunauté"
import NavBarShop from "./Components/NavBarShop"
import NavBarSearch from "./Components/NavBarSearch"
import NavBarLogin from "../Login/NavBarLogin"
import NavBarUniversOfGaea from "./Components/NavBarUniversOfGaea"
import NavBarLanguage from "../Language/NavBarLanguage"
import NavBarSubMenu from "./SousMenu/NavBarSubMenu";
import axios from 'axios';
import i18n from "../../../i18n";

export default class NavBarReact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displaySubMenu: false,
            buttonName: "",
            isLoggedIn: false,
            currentLanguage: "fr"
        };
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    componentDidMount() {
        axios.get(`/recuperer_langue`)
            .then(response => {
                if (i18n.language !== response.data.langue) {
                    i18n.changeLanguage("response.data.langue");
                }
                this.setState({ currentLanguage: response.data.langue })
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    handleOnClick(name) {
        this.setState({
            displaySubMenu: !this.state.displaySubMenu,
            buttonName: name
        })
        // console.log("this.state.displaySubMenu", this.state.displaySubMenu, this.state.buttonName);
    }

    changeLangueToEn() {
        axios.get(`/recuperer_langue`)
            .then(response => {
                if (response.data.langue !== "en") {
                    window.location.href = window.location.pathname.replace("/" + response.data.langue, "/en");
                }
                this.setState({ currentLanguage: "en" })
            })
            .catch(function (error) {
                // console.log(error);
            });
        i18n.changeLanguage("en");
    };

    changeLangueToFr() {
        axios.get(`/recuperer_langue`)
            .then(response => {
                if (response.data.langue !== "fr") {
                    window.location.href = window.location.pathname.replace("/" + response.data.langue, "/fr");
                }
                this.setState({ currentLanguage: "fr" })

            })
            .catch(function (error) {
                // console.log(error);
            });
        i18n.changeLanguage("fr");
    };

    render() {
        console.log(this.props.userRole);
        return (
            <div className="container-navbar-global">
                <div className="container-navbar-menu">
                    <NavBarLogo />
                    <nav>
                        <ul>
                            <NavBarHome handleOnClick={this.handleOnClick} />
                            <NavBarQuiSommeNous handleOnClick={this.handleOnClick} />
                            <NavBarServices handleOnClick={this.handleOnClick} />
                            <NavBarActualités handleOnClick={this.handleOnClick} />
                            <NavBarNouveautés handleOnClick={this.handleOnClick} />
                            <NavBarCommunauté handleOnClick={this.handleOnClick} />
                            <NavBarShop handleOnClick={this.handleOnClick} />
                        </ul>
                    </nav>
                    <NavBarSearch />
                    <NavBarLogin handleOnClick={this.handleOnClick} userRole={this.props.userRole} />
                    <NavBarLanguage currentLanguage={this.state.currentLanguage} onChangeToFr={this.changeLangueToFr} onChangeToEn={this.changeLangueToEn} />
                    <NavBarUniversOfGaea />
                </div>
                {this.state.displaySubMenu ? <NavBarSubMenu contenuARendre={this.state.buttonName} langue={this.state.currentLanguage} /> : ""}
            </div>
        )
    }
}