import React, { Component } from "react";
import { Trans } from "react-i18next";

export default class NavBarMonCompte extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="navbar-menu-moncompte">
                <a href="/profile/entreprise">
                    <Trans i18nKey="compte">Mon Compte</Trans>
                </a>
            </div>

        );
    }
}