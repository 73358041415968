import React, { Component } from "react";
import { Trans } from "react-i18next";


export default class NavBarQuiSommeNous extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(e) {
        e.preventDefault();
        this.props.handleOnClick("QuiSommesNous");
    }

    render() {
        return (
            <li className="navbar-menu-quisommenous">
                <a onClick={this.handleClick} href="">
                    <Trans i18nKey="quisommes">Qui sommes-nous</Trans>
                </a>
            </li>
        );
    }
}