import React, { Component } from "react";

//Le Composant attent en Props que l'appel avec le UserRole
export default class NavBarLogo extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container-navbar-logo">
                <a href="/" >
                    <img className="image-logo" src="/images/LogoRV_blanc.png" />
                </a>
            </div>
        );
    }
}