import React, { Component } from "react";
import { Trans } from "react-i18next";

export default class NavBarUniversOfGaea extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="navbar-menu-universeofgaea">
                <a href="https://www.gaea21.org/" target="_blank">
                    <img src="/images/NavBar/UniverseOfGaea-orange.png" alt="UniverseOfGaea" />
                </a>
            </div>
        );
    }
}