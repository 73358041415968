import React, { Component } from "react";
import NavBarLogon from "./Components/NavBarLogon"
import NavBarInscription from "./Components/NavBarInscription"
import NavBarMonCompte from "./Components/NavBarMonCompte"
import NavBarLogout from "./Components/NavBarLogout"

export default class NavBarLogin extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        console.log(this.props.userRole);
        return (
            <div className="container-navbar-login">
                {this.props.userRole !== "NOROLE" ? <NavBarMonCompte /> : <NavBarInscription />}
                <div className="navBar-login-separator">|</div>
                {this.props.userRole !== "NOROLE" ? <NavBarLogout /> : <NavBarLogon  handleOnClick={this.props.handleOnClick}/>}
            </div>
        );
    }
}