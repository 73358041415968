import React, { Component } from "react";
import { Trans } from "react-i18next";

export default class NavBarInscription extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(e) {
        e.preventDefault();
        this.props.handleOnClick("Sinscrire");
    }

    render() {
        return (
            <div className="navbar-menu-inscription">
                <a href="/inscription-entreprise">
                    <Trans i18nKey="sinscrire">S'inscrire</Trans>
                </a>
            </div>
        );
    }
}