//  import slp1 from '../../../../public/images/homeCompany/programmes-associes/Composant_6-10.jpg'
import slp1 from '../../../../public/images/homeCompany/programmes-associes/osezlentreprenariat.jpg'
import slp2 from '../../../../public/images/homeCompany/programmes-associes/ges.jpg'
import slp3 from '../../../../public/images/homeCompany/programmes-associes/grow_your_own.jpg'
import slp4 from '../../../../public/images/homeCompany/programmes-associes/green_coaching_indv_famille.jpg'
import slp5 from '../../../../public/images/homeCompany/programmes-associes/consom_action.jpg'
import slp6 from '../../../../public/images/homeCompany/programmes-associes/eco_geste.jpg'
import slp7 from '../../../../public/images/homeCompany/programmes-associes/osezlentreprenariat.jpg'
import slp8 from '../../../../public/images/homeCompany/programmes-associes/plateforme_initiative_1.jpg'
import React, {Component} from 'react';
import { Trans } from 'react-i18next';
import './AssociatedPrograms.scss';

class EvaluationSystem extends Component {


    render() {
        return (
            <div className="associatedprogramme">

                <div>
                    <h2 className="associatedprogramme-title"><Trans i18nKey="AssociatedPrograms">Programmes associés</Trans></h2>
                </div>

                {/* <div className="programmes">
                    <div className="programme">
                            <img className="programme-image-1"
                                src={slp1}
                                onClick="SustainableLivingProgram();"/>
                                                       
                            <img className="programme-image-2"
                                src={slp2}
                                onClick="SustainableLivingProgram();"/>
                       
                        <img className="programme-image-3"
                                src={slp3}
                                onClick="SustainableLivingProgram();"/>
                        <img className="programme-image-4"
                                src={slp4}
                                onClick="SustainableLivingProgram();"/>
                        <img className="programme-image-5"
                                src={slp5}
                                onClick="SustainableLivingProgram();"/>
                        <img className="programme-image-6"
                                src={slp6}
                                onClick="SustainableLivingProgram();"/>
                        <img className="programme-image-7"
                                src={slp7}
                                onClick="SustainableLivingProgram();"/>
                        <img className="programme-image-8"
                                src={slp8}
                                onClick="SustainableLivingProgram();"/>

                    </div>
                </div> */}
                <div className="programmes2">
                    <div className="div_img">
                        <img className="programme-image-1"
                                src={slp1} />
                        <p className="programmes_title">Sustainable<br/> Living<br/> Program</p>
                    </div>
                    <div className="div_img">
                        <img className="programme-image-1"
                                src={slp2} />
                        <p className="programmes_title">Green<br/> Events<br/> Series</p>
                                            </div>
                    <div className="div_img">
                        <img className="programme-image-1"
                                src={slp3} />
                        <p className="programmes_title">Grow<br/> Your<br/> Own</p>
                                            </div>
                    <div className="div_img">
                        <img className="programme-image-1"
                                src={slp4} />
                        <p className="programmes_title">Green<br/> Coaching</p>
                                            </div>
                    <div className="div_img">
                        <img className="programme-image-1"
                                src={slp5} />
                        <p className="programmes_title">Consom'action</p>
                                            </div>
                    <div className="div_img">
                        <img className="programme-image-1"
                                src={slp6} />
                        <p className="programmes_title">Eco-gestes<br/>&<br/> Green ID</p>
                                            </div>
                    <div className="div_img">
                        <img className="programme-image-1"
                                src={slp7} />
                        <p className="programmes_title">Osez<br/>l'Entrepeunariat<br/> Program</p>
                                            </div>
                    <div className="div_img">
                        <img className="programme-image-1"
                                src={slp8} />
                        <p className="programmes_title">Plateforme<br/> des<br/> Initiatives</p>
                    </div>
                </div>
            </div>

    )
    }
    }

    export default EvaluationSystem;