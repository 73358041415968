import React, { Component } from "react";

export default class NavBarSearch extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container-search">
                <a href="/404">
                    <img src="/images/NavBar/loupe.png" alt="search" />
                </a>
            </div>
        );
    }
}