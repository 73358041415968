import React, { Component } from "react";
import { Trans } from "react-i18next";

export default class NavBarActualités extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(e) {
        e.preventDefault();
        this.props.handleOnClick("Actualites");
    }

    render() {
        return (
            <li>
                <a onClick={this.handleClick} href="">
                    <Trans i18nKey="actualites">Actualités</Trans>
                </a>
            </li>
        );
    }
}